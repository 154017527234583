import React from 'react';
import AboutMeContent from '../../components/AboutMeContent';
import ContactForm from '../../components/ContactForm';

export default function AboutMe() {
  return (
    <main className='main'>
      <AboutMeContent />
      <ContactForm />
    </main>
  );
}